import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { View, StyleSheet, Image, Dimensions } from "react-native";
import { imgVisbility, imgVisbilityOff, backArrow, deleteButton, folderImage, retrieve } from "./assets";
import { CardMedia, IconButton, Box, ButtonBase } from "@mui/material"
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"
import { TouchableOpacity } from "react-native-gesture-handler";
type MediaType = 'image' | 'video' | 'pdf' | null | "";
// Customizable Area End

import TrashController, {
  Props,
  configJSON,
} from "./TrashController";

export default class Trash extends TrashController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.deleteListFolder = this.deleteListFolder.bind(this); // Make sure it's bound
    // Customizable Area End
  }

  // Customizable Area Start
  renderMedia = (
    mediaType: MediaType,
    mediaUrl: string,
    downloadAndOpenPdf: (url: string) => void
  ) => {
    if (mediaType === null) {
      return (
        <CardMedia
          component="img"
          image={folderImage}
          alt="Image"
          sx={{ width: "100%", height: 200 }}
        />
      )
    }
    switch (mediaType) {
      case 'image':
        return (
          <CardMedia
            component="img"
            image={mediaUrl}
            alt="Image"
            sx={{ width: "100%", height: 200 }}
          />
        );
      case 'video':
        return (
          <CardMedia
            component="video"
            src={mediaUrl}
            controls
            sx={{ width: "100%", height: 200 }}
          />
        );
      case "pdf":
        return (
          <IconButton onClick={() => window.open(mediaUrl, "_blank")}>
            <PictureAsPdfIcon sx={{ fontSize: 50, color: "red" }} />
          </IconButton>
        );
      default:
        return null;
    }
  };
  renderFolderItems = () => {
    return (<>
      {this.state.folders.map((folder: any, index: any) => {
        const files = folder.attributes.files?.data || [];
        return (
          <View key={folder.attributes.id} >
            {files.map((file: any, index: any) => {
              const fileUrls = file.attributes.image;
              const mediaType = this.getMediaType(fileUrls);
              return (
                <View style={{ flexDirection: "row", alignItems: "center", marginTop: 10, justifyContent: "center" }}>
                  <View key={file.attributes.id} style={[styles.imageContainer1, { flex: 1 }]}>
                    <View>
                      {this.renderMedia(mediaType, fileUrls, this.downloadAndOpenPdf)}
                    </View>
                  </View>
                  <Button
                    icon={
                      <Image
                        source={deleteButton}
                        style={{
                          width: 30,
                          height: 30
                        }}
                      />
                    }
                    testID={"btnDelete" + index}
                    style={{ width: 50, marginLeft: 1 }}
                    onPress={() => this.destroyFileFolder(file.attributes.id)}
                  />
                </View>
              )
            })}
          </View>
        );
      })}
    </>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        backgroundColor: "#ffff",
        width: "100%",

      }}>
        <Box sx={styles.mainWrapper}>
          <Typography style={styles.titleText}>{configJSON.labelTitleText}</Typography>
          <Typography style={styles.bodyText}>{configJSON.labelBodyText}</Typography>
          <Box style={styles.inputStyle}>
            <Input
              data-test-id="txtInput"
              placeholder={configJSON.txtInputPlaceholder}
              label={`This is the received value: ${this.state.txtSavedValue}`}
              {...this.txtInputProps}
              rightIcon={
                <Button
                  data-test-id="btnTogglePassword"
                  aria-label="toggle password visibility"
                  {...this.btnShowHideProps}
                  style={{ backgroundColor: "" }}
                  icon={this.state.enableField ? (
                    <img src={imgVisbility} />
                  ) : (
                    <img src={imgVisbilityOff} />
                  )}
                />
              }
            />
          </Box>
          <Box sx={styles.buttonStyle}>
            <Button
              data-test-id="btnAddExample"
              gradientColors={[styles.buttonStyle.backgroundColor, styles.buttonStyle.backgroundColor]}
              onPress={() => this.doButtonPressed()}
              text={configJSON.btnExampleTitle} />
          </Box>
          <View style={{ marginBottom: 100, width: "100%" }}>
            {
              this.state.showListOfFile ? (
                <View>
                  <View>
                    <View style={styles.backArrowView}>
                      <IconButton id={"toggleImageChecked"} aria-label="delete" onClick={this.handleBackArrowForFile}>
                        <Image source={backArrow} style={styles.backImage} />
                      </IconButton>
                      <Typography
                        data-test-id="labelBody"
                      >{configJSON.labelBodyText}</Typography>
                      <Button
                        text="Trash"
                        testID={"btnShowTrash"} style={styles.deleteContainer} onPress={this.handleTrashForfile} >
                      </Button>
                    </View>
                    <Box>
                      {this.state.showListOfFile && (
                        <>
                          {this.renderFolderItems()}
                        </>
                      )}
                    </Box>
                  </View>
                </View>)
                :
                (
                  <Box sx={{ marginBottom: 100 }}>
                    {this.state.showTrashList ?
                      <View>
                        <Box style={{ flexDirection: 'row', padding: 10, marginBottom: 20 }}>
                          <IconButton id={"toggleImageChecked"} aria-label="delete" onClick={() => this.setTrashListHide()}
                            style={{ marginLeft: 5 }}
                          >
                            <Image source={backArrow} style={{ width: 30, height: 30 }} />
                          </IconButton>
                          <Typography
                            data-test-id="labelBody"
                          >{configJSON.backTitleText}</Typography>
                        </Box>
                        <View style={styles.listContainer}>
                          <View style={styles.listContainer}>
                            {this.state.trashListItem.map((item, index) => {
                              {      
                                return item.attributes.files.map((file: any) => {
                                  const mediaType = this.getMediaType(file.url);
                                  return (
                                    <>
                                      <View style={{ flexDirection: "row", alignItems: "center", marginTop: 10, justifyContent: "space-between" }}>
                                        <View key={file.id}>
                                          <View style={{ width: 250 }}>
                                            {this.renderMedia(mediaType, file.url, this.downloadAndOpenPdf)}
                                          </View>
                                        </View>
                                        <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                          <IconButton id={"btnDelete" + index} onClick={() => this.deleteForcefullyListItem(item.id)}
                                          >
                                            <Image source={deleteButton} style={{ width: 30, height: 30, marginRight: 20 }} />
                                          </IconButton>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                              cursor: "pointer", 
                                            }}
                                          >
                                          <Box onClick={() => this.restoreItem(item.id)} data-testid="restoreFileID" component="img" src={retrieve} alt="Retrieve Icon" sx={{ marginTop: "25px", ...styles.retrieveImage }} /><Typography style={{ ...styles.restoreText }}>Restore</Typography></Box>
                                        </View>
                                      </View>
                                      <View style={[styles.imageContainer1]}>
                                        <Typography>Deleted Date:</Typography>
                                        <Typography>{this.formatDeletedDate(item.deleted_at)}</Typography>
                                      </View>
                                    </>
                                  );
                                })
                              }
                            })}
                          </View>
                          <View style={styles.listContainer}>       
                            {this.state.deletedfolderListItem.map((folder: any) => 
                            (
                           
                              <>
                                <View style={{ marginTop: 10, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                  <TouchableOpacity style={styles.folderImageBtn}>
                                    <Image source={folderImage} style={styles.folderImages} />
                                    <View style={{ width: 250 }}>
                                      <Typography numberOfLines={1} style={styles.folderNameText}>{folder.attributes.name}</Typography>
                                    </View>
                                  </TouchableOpacity>
                                  <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                    <IconButton id="btnDelete" onClick={() => this.deleteForcefullyListFolderItem(folder.id)}
                                    >
                                      <Image source={deleteButton} style={{ width: 30, height: 30, marginRight: 20 }} />
                                    </IconButton>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer", 
                                        flexDirection: "column",
                                      }} 
                                    >
                                     <Box onClick={() => this.restoreItemFolder(folder.id)} data-testid="restoreID" component="img"  alt="Retrieve Icon"  src={retrieve} sx={{marginTop: "25px",  ...styles.retrieveImage,}}/> <Typography style={{ ...styles.restoreText }}>Restore</Typography>  </Box>
                                  </View>
                                </View>
                                <View style={[styles.imageContainer1]}>
                                  <Typography>Deleted Date:</Typography>
                                  <Typography>{this.formatDeletedDate(folder.attributes.deleted_at)}</Typography>
                                </View>
                              </>
                            ))}
                          </View>
                          <View style={styles.listContainer}>
                            {this.state.trashListFile.map((file: any) => {
                              const mediaType = this.getMediaType(file.attributes.image);
                              return (
                                <>
                                  <View style={{ flexDirection: "row", alignItems: "center", marginTop: 10, justifyContent: "space-between" }}>
                                    <View key={file.id}>
                                      <View style={{ width: 250 }}>
                                        {this.renderMedia(mediaType, file.attributes.image, this.downloadAndOpenPdf)}
                                      </View>
                                    </View>
                                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                      <IconButton id="homebton" onClick={() => this.forcefullyDeleteFile(file.id)}
                                      >
                                        <Image source={deleteButton} style={{ height: 30, width: 30, marginRight: 20 }} />
                                      </IconButton>
                                      <Box
                                        sx={{
                                          cursor: "pointer", 
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center",   
                                        }}
                                      >
                                      <Box onClick={() => this.restoreItemFile(file.id)} data-testid="RestoreItemID" sx={{ marginTop: "25px", ...styles.retrieveImage }} component="img" src={retrieve} alt="Retrieve Icon" /><Typography style={{ ...styles.restoreText }}>Restore</Typography></Box>
                                    </View>
                                  </View>
                                  <View style={[styles.imageContainer1]}>
                                    <Typography>Deleted Date:</Typography>
                                    <Typography>{this.formatDeletedDate(file.attributes.deleted_at)}</Typography>
                                  </View>
                                </>
                              )
                            })}
                          </View>
                        </View>
                      </View>
                      :
                      <>
                        <View style={{ flexDirection: "row", alignItems: "center", marginTop: 10, justifyContent: "space-between" }} >
                          <Typography
                            data-test-id="labelBody"
                            style={styles.body}
                          >
                            {configJSON.labelBodyText}
                          </Typography>
                          <Button
                            text="Trash"
                            testID={"btnShowTrash"}
                            style={styles.body}
                            onPress={() => this.setTrashListShow()} >
                          </Button>
                        </View>
                        <View >
                          {this.state.listItem.map((item, index) => {
                            {
                              return item.attributes.files.map((file: any) => {
                                const mediaType = this.getMediaType(file.url);
                                return (
                                  <>
                                    <View style={styles.showList}>
                                      <View style={{ width: 250 }}>
                                        {this.renderMedia(mediaType, file.url, this.downloadAndOpenPdf)}
                                      </View>
                                      <IconButton
                                        data-testid={"btnSHOW"}
                                        onClick={() => this.deleteListItem(item.id)}
                                      >
                                        <Image
                                          source={deleteButton}
                                          style={{ width: 30, height: 30 }}
                                        />
                                      </IconButton>
                                    </View>
                                  </>
                                );
                              })
                            }
                          })}
                        </View>
                        <View style={styles.listContainer}>
                          {this.state.folders.map((folder: any) => (
                            <View style={styles.showList}>
                              <ButtonBase sx={styles.folderImageBtn}
                                id="listbtn"
                                onClick={
                                  () => {
                                    this.handleClickOnFolder(folder.attributes.id)
                                  }}>
                                <Image source={folderImage} style={styles.folderImages} />
                                <Box style={{ width: 200 }}>
                                  <Typography style={styles.folderNameText}>
                                    {folder.attributes.name}
                                  </Typography>
                                </Box>
                              </ButtonBase>
                              <IconButton
                                id="deleteFolder"
                                onClick={() => this.deleteListFolder(folder.id)}
                                style={{ marginLeft: 5 }}
                              >
                                <Image
                                  source={deleteButton}
                                  style={{ width: 30, height: 30 }}
                                />
                              </IconButton>
                            </View>
                          ))}
                        </View>
                      </>
                    }
                  </Box>
                )
            }
          </View>
        </Box>
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  mainWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    backgroundColor: "#ffff",
    width: "50%",
    fontFamily: "Roboto, sans-serif"
  },
  listContainer: {
    flex: 1,
  },
  imageContainer1: {
    flexDirection: "row",
    alignItems: 'center',
    marginBottom: 10,
  },
  imageStyles: {
    height: Dimensions.get("window").width / 3,
    width: '100%',
    borderWidth: 1,
    borderColor: "#ccc"
  },
  TitleContainer: {
    flex: 1,
    flexWrap: "wrap",
    flexDirection: "row"
  },
  body: {
    marginBottom: 32,
    textAlign: "left",
    marginVertical: 8,
    padding: 10,
  },
  imageStyle: {
    height: Dimensions.get("window").width / 3,
    width: '90%',
    borderWidth: 1,
    borderColor: "#ccc"
  },
  deleteContainer: {
    position: "absolute",
    bottom: 10,
    right: 10,
    zIndex: 1,
  },
  titleText: {
    fontSize: 30,
    paddingVertical: 10,
    fontWeight: "600",
  },
  bodyText: {
    fontSize: 20,
    paddingVertical: 15,
    fontWeight: "400",
  },
  inputStyle: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    backgroundColor: "rgb(98, 0, 238)",
  },
  backArrowView: {
    flexDirection: 'row',
    padding: 10,
    marginBottom: 20
  },
  backImage: {
    width: 20,
    height: 20
  },
  TitleContainer1: {
    flex: 1,
    flexWrap: "wrap",
    flexDirection: "row"
  },
  imageContainer2: {
    flexDirection: "row",
    alignItems: 'center',
    marginBottom: 10,
    justifyContent: "space-between",
    width: 260
  },
  folderImageBtn: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  folderImages: {
    height: 50,
    width: 50,
    resizeMode: "contain"
  },
  folderNameText: {
    marginLeft: 3,
    fontSize: 20,
    fontWeight: "400"
  },
  retrieveImage: {
    width: 20,
    height: 20,
    resizeMode: "contain"
  },
  folderContainer2: {
    flexDirection: "row",
    alignItems: 'center',
    marginBottom: 10,
    justifyContent: "space-evenly",
  },
  restoreText: {
    fontWeight: "400",
    fontSize: 10
  },
  showList: { flexDirection: "row", alignItems: "center", marginTop: 10, justifyContent: "space-between" }
});
// Customizable Area End