import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";

export interface ListItem {
  id: string;
  type: string | null;
  attributes: {
    name: string | null;
    description: string | null;
    image:string;
    video:string;
    files: Array<any>;
  };
}
export interface DeleteItem{
  attributes: any;
  image_url: string;
  id: string;
  type: string | null;
  deleted_at: string | null;
  item_attributes: {
    name: string | null;
    description: string | null;
    image_url:string;
    video_url:string;
  };
}


interface MediaType {
  type: 'video' | 'image' | 'pdf' | null;
}
interface PDFDownload {
  downloadAndOpenPdf: (pdfUrl: string) => void;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  listItem:ListItem[];
  showTrashList:boolean;
  trashListItem:DeleteItem[];
  showListOfFile:boolean,
  files: any,
  folders:Array<any>
  deletedfolderListItem:Array<any>
  trashListFile:Array<any>
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TrashController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getListItemsApiCallId = "";
  deleteListItemApiCallId = "";
  getTrashListItemsApiCallId= "";
  deleteListFolderApiCallId= "";
  deleteForcefullyListItemApiCallId="";
  deleteForcefullyFolderListItemApiCallId="";
  restoreItemApiCallId="";
  restoreFolderItemApiCallId="";
  restoreItemFileApiCallId="";
  restoreItemFolderApiCallId="";
  deleteFileFolderApiCallId="";
  deleteFileFolderForcefullApiCallId="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      listItem:[],
      showTrashList:false,
      trashListItem:[],
      showListOfFile:false,
      files: [],
      folders:[],
      deletedfolderListItem:[],
      trashListFile:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    if (!responseJson.errors) {
      if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        this.handleApiResponse(responseJson, message);
      } else {
        this.handleErrorResponse(message);
      }
    } else {
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      this.parseApiCatchErrorResponse(errorResponse);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();

    this.getListItems();
    this.setInputValue('')
    this.setEnableField()
  }
  handleApiResponse(responseJson: any, message: any) {
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
    if (this.isApiCallMatched(this.getListItemsApiCallId, dataMessage)) {
      this.updateListItems(responseJson);
    } else if (this.isApiCallMatched(this.getTrashListItemsApiCallId, dataMessage)) {
      this.updateTrashListItems(responseJson);
    } else if (this.isApiCallMatched(this.deleteListItemApiCallId, dataMessage)) {
      this.handleDeleteListItem(responseJson);
    } else if (this.isApiCallMatched(this.deleteListFolderApiCallId, dataMessage)) {
      this.handleDeleteListFolder(responseJson);
    } else if (this.isApiCallMatched(this.deleteForcefullyListItemApiCallId, dataMessage)) {
      this.handleDeleteForcefully(responseJson);
    } else if (this.isApiCallMatched(this.deleteForcefullyFolderListItemApiCallId, dataMessage)) {
      this.handleDeleteForcefully(responseJson);
    } else if (this.isApiCallMatched(this.restoreItemApiCallId, dataMessage)) {
      this.handleRestoreItem(responseJson);
    } else if (this.isApiCallMatched(this.restoreFolderItemApiCallId, dataMessage)) {
      this.handleRestoreFolderItem(responseJson);
    }else if(this.isApiCallMatched(this.restoreItemFileApiCallId,dataMessage)){
      this.handleRestoreFile(responseJson)
    }else if(this.isApiCallMatched(this.restoreItemFolderApiCallId,dataMessage)){
      this.handleRestoreFolderItem(responseJson)
    }else if(this.isApiCallMatched(this.deleteFileFolderApiCallId,dataMessage)){
      this.handleFileFolder()
    }else if(this.isApiCallMatched(this.deleteFileFolderForcefullApiCallId,dataMessage)){
      this.handleDeleteForcefully(responseJson);
    }
  }
  
  isApiCallMatched(apiCallId: string, dataMessage: any) {
    return apiCallId && apiCallId === dataMessage;
  }
  handleFileFolder(){
    alert("file moved to trash")
    this.getListItems()
    this.getTrashListItems()
      }
  handleRestoreFile(responseJson:any){
    this.getTrashListItems();
    this.updateTrashListItems(responseJson)
    alert(responseJson.message);
  }
  handleItemRestoreFolder(responseJson:any){
    this.getTrashListItems();
    alert(responseJson.message)
  }
  updateListItems(responseJson: any) {
    this.setState({
      listItem: responseJson.items.data.reverse(),
      folders: responseJson.folders.data,
    });
  }
  updateTrashListItems(responseJson: any) {   
    const deletedfolderListItem = responseJson.deleted_folders?.data.filter(
      (folder: any) => folder.attributes.is_deleted
    ) || [];
    const trashListItem = responseJson.deleted_items?.data.filter(
      (item: any) => item.attributes.is_deleted
    ) || [];
    const trashListFile = responseJson.deleted_files?.data.filter(
      (file: any) => file.attributes.is_deleted 
    ) || [];
    this.setState({
      deletedfolderListItem,
      trashListItem,
      trashListFile,
    });  
  }
  handleDeleteListItem(responseJson: any) {
    this.getListItems();
    alert(responseJson.message);
  }
  handleDeleteListFolder(responseJson: any) {
    this.setState({ showListOfFile: false }, () => {
      this.getListItems();
    });
    alert(responseJson.message);
  }
  
  handleDeleteForcefully(responseJson: any) {
    this.getTrashListItems();
    alert(responseJson.message);
  } 
  handleRestoreItem(responseJson: any) {
    this.getTrashListItems();
    alert(responseJson.message);
  } 
  handleRestoreFolderItem(responseJson: any) {
    this.getTrashListItems();
    alert(responseJson.message);
  }
  handleErrorResponse(message:any) {
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    this.parseApiCatchErrorResponse(errorResponse);
  }
  getListItems = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getListItemsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listItemsApiEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType 
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getTrashListItems = () => {
    this.setState({trashListItem:[]})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTrashListItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.trashListItemsApiEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteListItem = (itemId: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteListItemApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `bx_block_trash/items/destroy_item?id=${itemId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteListFolder = (folderId: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteListFolderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_trash/folders/destroy_folder?id=${folderId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteForcefullyListItem = (itemId: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteForcefullyListItemApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listForcefullyItemsApiEndpoint + "/" + `${itemId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteForcefullyListFolderItem = (folderId: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteForcefullyFolderListItemApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.listForcefullyFolderItemsApiEndpoint + "/" + `${folderId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  restoreItem = (itemId: string) => {
    const header = {
      "Content-Type": "multipart/form-data",
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.restoreItemApiCallId = requestMessage.messageId;
    const formData = new FormData();
    formData.append('id', itemId);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_trash/items/restore_item?id=${itemId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  restoreFolderItem = (folderId: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.restoreFolderItemApiCallId = requestMessage.messageId;
  
    const formData = new FormData();
    formData.append('id', folderId);
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_trash/folders/restore_folder?id=${folderId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  setTrashListShow(){
    this.getTrashListItems()
    this.setState({showTrashList:true})
  }
  setTrashListHide(){
    this.setState({showTrashList:false})
    this.getListItems()
  }

  getMediaType = (mediaUrl: string): MediaType['type'] => {
    if (mediaUrl) {
      const mediaExtension = mediaUrl.split('.').pop()?.toLowerCase();
      const videoExtensions = ['mp4', 'mov', 'avi', 'mkv'];
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
      const pdfExtensions = ['pdf'];
  
      if (mediaExtension && videoExtensions.includes(mediaExtension)) {
        return 'video';
      } else if (mediaExtension && imageExtensions.includes(mediaExtension)) {
        return 'image';
      } else if (mediaExtension && pdfExtensions.includes(mediaExtension)) {
        return 'pdf';
      }
    }
    return null;
  };



 downloadAndOpenPdf: PDFDownload['downloadAndOpenPdf'] = (pdfUrl: string) => {
    try {
      const pdfData = document.createElement('a');
      pdfData.href = pdfUrl;
      pdfData.target = '_blank';
      document.body.appendChild(pdfData);
      pdfData.click();
      document.body.removeChild(pdfData);
    } catch (error) {
    }
  };
  formatDeletedDate = (deletedAt:string | null) => {
    const date = moment(deletedAt);
    return date.format('DD-MM-YYYY HH:mm');
  };
  handleBackArrowForFile=()=>{
 this.setState({showListOfFile:false},()=>{
 this.getListItems()
 })
}
handleTrashForfile=()=>{
   this.setState({showListOfFile:false,showTrashList:true})
   this.getTrashListItems()
}
handleClickOnFolder = (folderId: string) => {
  const folder = this.state.folders.find((folder) => folder.attributes.id === folderId);
  if (folder) {
    const files = folder.attributes.file_url;
    this.setState({ files,folders: [folder], showListOfFile: true, showTrashList: false },()=>{
    });
  }
};
restoreItemFile = (itemId: string) => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.restoreItemFileApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `PUT`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_trash/files/restore_file?id=${itemId}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
restoreItemFolder = (itemId: string) => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.restoreItemFolderApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    `PUT`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_trash/folders/restore_folder?id=${itemId}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};
destroyFileFolder = (fileId: string) => {
  const header = {
    "Content-Type": configJSON.validationApiContentType,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.deleteFileFolderApiCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deleteApiMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_trash/files/destroy_file?id=${fileId}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
forcefullyDeleteFile =(fileId:any)=>{
  const header = {
    "Content-Type": configJSON.validationApiContentType,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.deleteFileFolderForcefullApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deleteApiMethod
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_trash/files/${fileId}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}
  // Customizable Area End
}
